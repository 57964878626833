/*!

=========================================================
* Now UI Kit PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles

import "assets/css/bootstrap.min.css";
import "assets/css/now-ui-kit.min.css";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/demo/nucleo-icons-page-styles.css";

import "assets/demo/em.css";
// pages
import Index from "views/Index.js";
import LandingPageEM from 'views/examples/LandingPageEM';
import NucleoIcons from "views/NucleoIcons.js";
import SignupPage from "views/examples/SignupPage.js";
import StudiesEM from "./views/sections-sections/StudiesEM";
import NewsEM from "./views/sections-sections/NewsEM";
import Cribsy from "views/sections-sections/Cribsy";
import Cribsy_Feedback from "views/sections-sections/Cribsy-Feedback";
import PrivacyComponent from "components/Footers/Privacy";

// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={props => <LandingPageEM {...props} />} />
     
      <Route
      path="/landing-page-em"
      render={props => <LandingPageEM {...props} />}
      />
     
      <Route
      path="/projects"
      render={props => <StudiesEM {...props} projectname='emma' projectindex={0}  />}
      />

      <Route
      path="/emma"
      render={props => <StudiesEM {...props} projectname='emma' projectindex={0} />}
      />

      <Route
      path="/emma-es"
      render={props => <StudiesEM {...props} projectname='emma' projectindex={3} />}
      />
      <Route
      path="/mimm"
      render={props => <StudiesEM {...props} projectname='mimm' projectindex={2} />}
      />

      <Route
      path="/minutes"
      render={props => <StudiesEM {...props} projectname='minutes' projectindex={1} />}
      />


      <Route
      path="/news"
      render={props => <NewsEM {...props} />}
      />

      <Route
      path="/cribsy"
      render={props => <Cribsy {...props} />}
      />
      <Route
      path="/cribsy-feedback"
      render={props => <Cribsy_Feedback {...props} />}
      />

      <Route
      path="/privacy"
      render= {props => <PrivacyComponent {...props}/>}
      />

      <Route
        path="/nucleo-icons"
        render={props => <NucleoIcons {...props} />}
      />
     
      <Route path="/sign-up" render={props => <SignupPage {...props} />} />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
