import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import bgImage from "assets/img/baby_guitar.jpg";
// core components

function LandingPageHeaderEM() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        console.log("pageheader: {}",pageHeader)
        if (pageHeader.current) {
          pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
        }
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require('assets/img/baby_guitar.jpg') + ")"
            /*bg26.img or baby_guitar.jpg or grascale-toddlers-foot.jpg*/
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Container>
        
            <h2 className="title">Hello. This is Early Markers.</h2>
            <h4>Advancing early screening for developmental disorders</h4>
            
            <div className="text-center">
              <Button
                size = "lg"
                className="btn-primary"
                href="cribsy">
                Introducing Cribsy
              </Button>

              <p className="title">
                <a href="news#cribsy-sbir-2024">
                <b> Now funded by a Phase I SBIR (NIH/NICHD)
                </b>
                </a>
                
              </p>
            </div>
            <div className="text-center">
              <Button
                className="btn-icon btn-round mr-1"
                color="info"
                href="https://www.facebook.com/earlymarkers"
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              <Button
                className="btn-icon btn-round mr-1"
                color="info"
                href="https://twitter.com/earlymarkers"
              >
                <i className="fab fa-twitter"></i>
              </Button>
              
            </div>
           
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeaderEM;
