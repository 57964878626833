import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledCollapse
} from "reactstrap";

import { useState } from "react";
// core components
import ScrollTransparentNavbarEM from "components/Navbars/ScrollTransparentNavbarEM";
import LandingPageHeaderEM from "components/Headers/LandingPageHeaderEM.js";
import FooterBlackEM from "components/Footers/FooterBlackEM";

function LandingPageEM(props) {
  const [pills, setPills] = React.useState("1");
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [resultMessage, setResultMessage] = useState('');

  
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const handleContactFormSubmit = () => {
    console.log('Handle form submit');
    const endpoint = 'https://el52532p3e.execute-api.us-east-1.amazonaws.com/default/sendContactEmailFunction';
    const body = JSON.stringify({
      name: name,
      email: email,
      message: message,
      phone: phone
    });
    const requestOptions = { method:'POST', body};

    fetch(endpoint, requestOptions)
    .then( (response) => {
      if (!response.ok) throw new Error('Error in fetch');
      return response.json();
    }).then ( (response) => {
      setResultMessage('Email sent successfully! Thank you for contacting us.')
    }).catch( (error) => { setResultMessage('Thank you.');});

  };

  return (
    <>
      <ScrollTransparentNavbarEM />
      <div className="wrapper">
        <LandingPageHeaderEM />
        <div className="section section-about-us" data-background-color="black">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Who we are</h2>

                <p> Listen to our story here:</p>
                  <iframe
                  frameBorder="0"
                  height="200"
                  loading="lazy"
                  scrolling="no"
                  src="https://www.buzzsprout.com/1785161/8978015-using-ai-to-detect-motor-delays-in-infants-interview-with-bharath-modayur?client_source=small_player&amp;iframe=true&amp;referrer=https%3A%2F%2Fwww.buzzsprout.com%2F1785161%2F8978015-using-ai-to-detect-motor-delays-in-infants-interview-with-bharath-modayur.js%3Fcontainer_id%3Dbuzzsprout-player-8978015%26player%3Dsmall\"
                  title="Your AI Injection, Using AI to Detect Motor Delays in Infants: Interview with Bharath Modayur\"
                  width="100%"
                  />
                  
                <p className="em-p-light">
                We are an early stage startup building innovative solutions that will assist clinicians in 
						screening infants for risks of neuromotor disorders. Gross motor delays are present in children with cerebral palsy. 
            Motor delays and atypical movements are risk indicators for autism spectrum disorders. In the US, 15-20% of children 
            have a developmental or behavioral disability, but less than a third of them get diagnosed before entering school, 
            preventing opportunities for early intervention. 
               </p>
               <p className="em-p-light">
                 We are building an automated, portable, low-cost, smartphone-based, early development 
                 screener that can be accessed from home and easily administered by clinical staff in a pediatric or community 
                 setting.
               </p>
           
              </Col>
            </Row>
            <div className="separator separator-info"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/toys-colorized.jpg") + ")"
                    }}
                  >
                    <p className="blockquote em-p-primary">
                      "An infant goes through a psychological revolution following onset of independent locomotion" <br></br>
                      <br></br>
                      <small></small>
                    </p>
                  </div>
                  <div
                    className="image-container image-left-bottom"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/studyroom-wall.jpg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/studyroom-adele.jpg") + ")"
                    }}
                  ></div>
                  <h3>
                    Why is motor development of an infant important?
                  </h3>
                  <p className="em-p-light">
                  Motor development plays a pivotal role in the overall development of the human infant. 
                  Early motor development has a cascading effect on social, cognitive, spatial memory, and both verbal and 
                  non-verbal communication development. 
                  Self-produced locomotion, starting with the transition to crawling and later walking, creates opportunities 
                  for the infant to interact with a complex environment and drives developmental changes in other domains. 
                  For instance, the infant’s postural control involved in sitting and reaching, and motor skills, such as pointing,
                   influence ‘social bids’ initiated in interactions with the caregiver. 
                   </p>
                   <p className="em-p-light">
                   For infants, prior to the onset of communication and social skills, motor activities are one of the earliest 
                   observable signals of development. Disruption of motor development is associated with generalized developmental 
                   delays and specific developmental disabilities, including speech/language impairment, cerebral palsy (CP), 
                   Autism Spectrum Disorder (ASD), developmental coordination disorder (DCD), 
                   and intellectual disability. 
                   </p>
                   <p className="em-p-light">
                  Early motor delays may help predict later cognitive performance, communication delays, and anxiety disorders. 
                  While not a core diagnostic feature of ASD, early motor delays and atypical motor development have been extensively 
                  implicated in infants who later developed ASD, a disorder that affects one in every 68 children. 
                  Differences in movement quality may signal developmental problems such as CP, a disorder that has a prevalence 
                  of 3.3 per 1000.</p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div
          className="testimonials-1 section-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg19.jpg") + ")"
          }}
        >
          <Container  id="about-em">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">The People</h2>
                <h5 className="description text-white">
                
            We are comprised of entrepreneurs, clinicians, and academicians 
						with decades-long experience in infant development, early interventions, occupational therapy, 
            AI & computer vision, and development of medical technologies that improve health 
            outcomes. 
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/bharath_bw_small.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h5">Bharath</CardTitle>
                    <p className="category">founder, president</p>
                   
                  </CardFooter>
                  <Button color="neutral" id="contact1Toggler">

                  <i className="fa fa-chevron-down"></i>
                  </Button>
                </Card>
                <UncontrolledCollapse toggler="#contact1Toggler">
                  <Card>
                    <CardBody>
                    Bharath created this venture on a Phase I SBIR grant from National Institute of Mental Health (NIMD). 
							Bharath has extensive experience incubating, developing, and commercializing several critical medical applications. He also has leadership and entrepreneurial skills that have resulted in several startups. 
							One of them, Northwest Medical Physics Equipment (NMPE), went through a successful exit in 2006. 
              <br></br>
              <br></br>
              While at NMPE, Bharath led the development – in close coordination with radiation oncology specialists at clinical and academic centers – of external beam radiation therapy 
              planning and tumor localization systems and brought these innovations to market. The stereotactic treatment planning system, pReference, 
              has been used in the radiation treatment planning and delivery of over 4000 treatments at 45 sites in the US and across the world. The tumor localization system, ACCULOC, has been deployed at over 250 radiation therapy centers in the US and is still in wide use. 
              <br></br>
              <br></br>
              Bharath holds a Ph.D. in Electrical Engineering from the University of Washington and a clinical faculty
              appointment at the Elson S. College of Medicine (Washington State University).
                     </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/dc_bw_small.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h5">Darrell</CardTitle>
                    <p className="category">chief strategy officer</p>
                  </CardFooter>

                  <Button color="neutral" id="contact2Toggler">
                  <i className="fa fa-chevron-down"></i>
                  </Button>
                </Card>

                <UncontrolledCollapse toggler="#contact2Toggler">
                  <Card>
                    <CardBody>
                    Darrell is our Chief Strategy Officer. His business career began at Microsoft, where he held both product management and product planning roles. Mr. Craig was instrumental in helping to build Microsoft’s one-to-many developer evangelism program, the Microsoft Developer Network, from annual revenue in the tens of millions to the hundreds of millions. Mr. Craig then co-founded Think.Act, a consultancy that has helped clients in the technology space with business strategy, product and marketing management, decision support, and new initiative incubation since 2001. 
							Darrell has also been involved in a number of startups in the technology space, from augmented reality to transportation services, with roles as angel investor, marketing lead, formal advisor and consultant.
              <br></br>
              <br></br>
							At Early Markers, Darrell helped us navigate through the immersive entrepreneurship program, ICorps, hosted by NIH;
							helps us program manage our projects; and is developing programs that will allow our screening tools to reach parents.
              <br></br>
              <br></br>
              Darrell has an MBA from the University of Texas, Austin.
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/teresabwsmall.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                   
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h5">Teresa</CardTitle>
                    <p className="category">Chief Clinical Officer</p>
                  </CardFooter>
                  <Button color="neutral" id="contact3Toggler">
                  <i className="fa fa-chevron-down"></i>
                  </Button>
                </Card>

                <UncontrolledCollapse toggler="#contact3Toggler">
                  <Card>
                    <CardBody>
                    Teresa is our Chief Clinical Officer. She has specialized in neurological development through the life span to include early intervention and infant 
							motor skills, issues of pre-term infancy, and infant feeding as well as those affecting development of the school 
							age child.  She is certified in Ayres Sensory Integration® and enjoys understanding movement, sensation, and 
							behavior from a nervous system perspective.  
              <br></br>
              <br></br>
							Teresa is currently faculty at Baylor University.  She is delighted to present our emerging work at the 
							American Occupational Therapy Association (AOTA) national conference 
							"Using Machine Learning in an Automated Infant Motor Screening Tool for the Natural Environment."
              <br></br>
              <br></br>
              Teresa received her OT Doctorate from Chatham University (2016) and Pacific University School of Occupational Therapy (1993).
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/dlipino-small.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                   
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h5">David</CardTitle>
                    <p className="category">data scientist</p>
                   
                  </CardFooter>
                  <Button color="neutral" id="contact4Toggler">

                  <i className="fa fa-chevron-down"></i>
                  </Button>
                </Card>
                <UncontrolledCollapse toggler="#contact4Toggler">
                  <Card>
                    <CardBody>
                    David is a software developer who thrives at the intersection of cutting-edge research and high quality, commercial software development. David has previously worked at a radiation therapy software startup and a geospatial simulation startup. Yes, startups seem to be his thing. He has developed a wide range of software products used to this day in radiation oncology clinics around the US.

As part of a geospatial startup, Depiction, David was instrumental in developing the company's software development infrastructure and a concise approach to a complex problem: bringing geospatial simulation to the everyday user.
<br></br>
              <br></br>
At Early Markers, David is developing the web services to acquire, manage, and analyze behavioral data captured 
via video cameras.
<br></br>
<br></br>
David received a BS degree in Electrical Engineering from the University of Washington.
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/jason_shades.png")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                   
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h5">Jason</CardTitle>
                    <p className="category">lead software engineer</p>
                  </CardFooter>

                  <Button color="neutral" id="contact5Toggler">
                  <i className="fa fa-chevron-down"></i>
                  </Button>
                </Card>

                <UncontrolledCollapse toggler="#contact5Toggler">
                  <Card>
                    <CardBody>
                    Jason is an accomplished software developer with extensive experience in full stack web, mobile, and desktop development. After graduating
                    from the University of Washington with a CS degree, Jason cut his teeth first at Depiction, a geospatial startup firm, and later at LizardTech,
                    a geospatial software and consulting firm. In 2019, he returned to the original scene of crime by rejoining Bharath and David.
                    <br></br>
                    <br>
                    </br>
                    Known in his circles as the 'Pixel Whisperer' for his attention to detail and his ability to unwind complex
                    software problems, Jason is instrumental in moving Early Markers' research & development prototypes to
                    production code.
                    <br></br>
                    <br></br>
                    Jason holds a BS in Computer Science from the University of Washington.
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/mara-profile-bw.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                  
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h5">Mara</CardTitle>
                    <p className="category">research administrator</p>
                  </CardFooter>
                  <Button color="neutral" id="contact6Toggler">
                  <i className="fa fa-chevron-down"></i>
                  </Button>
                </Card>

                <UncontrolledCollapse toggler="#contact6Toggler">
                  <Card>
                    <CardBody>
                    Mara is our Research Administrator, our point person for administration of infant sessions where we gather movement data.
							Upon graduation (BSN with honors), Mara became a Registered Nurse working in a variety of settings, including home-care and school nursing, before 
							becoming a Research Nurse at Seattle Children's Hospital in 1999. There, she worked in the Cystic Fibrosis Research department for 2 years before transitioning
							to the role of Cystic Fibrosis Clinic Nurse. In both the research and clinic positions, she excelled at working with families of children of all ages,
							including infants.
              <br></br>
              <br></br>
							As a Research Nurse, she was part of a multidisciplinary team that developed the informed consent and study visit forms, and actively
							involved in patient recruitment, and conducted study visits. As a Clinic Nurse, she followed patients over time and actively assisted
							in all aspects of managing a complex chronic illness.
              <br></br>
              <br></br>

							Mara received her BSN, with honors, from Seattle University.
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/siva_bw_small.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                   
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h5">Naren</CardTitle>
                    <p className="category">chief scientist</p>
                  </CardFooter>

                  <Button color="neutral" id="contact7Toggler">
                  <i className="fa fa-chevron-down"></i>
                  </Button>
                </Card>

                <UncontrolledCollapse toggler="#contact7Toggler">
                  <Card>
                    <CardBody>
                    
							Naren has deep knowledge and experience in web technologies, UI-rich client systems, broad understanding of image processing, computer vision algorithms, with exposure to machine learning, pattern recognition, and conversant in interpreted languages (Python) to assist in rapid prototyping. 
              <br></br>
              <br></br>
              Naren has a rare combination of scientific expertise vital to the success of our projects and commercial software development skills and experience crucial for the success of this venture.
							<br></br>
              <br></br>
              Naren has a Ph.D. in Electrical Engineering from the University of Washington.
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </Col>
            </Row>
          </Container>
        </div>
       
        <div className="section section-contact-us text-center" >
          <Container id="contact-em">
            <h2 className="title">Contact Us</h2>
            <p className="em-p-dark">If you want to contact us on our ongoing studies or the Cribsy app, 
            please fill this form.</p>
            <Row>
             
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                
                <form id="ajax-contact"  onSubmit={handleContactFormSubmit}>
                 {/*https://el52532p3e.execute-api.us-east-1.amazonaws.com/default/sendContactEmailFunction">*/}
												<div class="form-group">                
													<input type="text" class="form-control" placeholder="Name" id="name" name="name" required
                          onChange = {(e)=>{setName(e.target.value); console.log('Name:', e.target.value);}}/>
												</div>
												<div class="form-group">                
													<input type="email" class="form-control" placeholder="Enter Email" id="email" name="email" required
                          onChange = {(e)=>{setEmail(e.target.value); console.log('Email:', e.target.value);}}/>
												</div>
												<div class="form-group">                
													<input type="phone" class="form-control" placeholder="Contact phone number" id="phone" name="phone" required
                          onChange = {(e)=>{setPhone(e.target.value); console.log('Phone:', e.target.value);}}/>
												</div>              
												<div class="form-group">
													<textarea class="form-control" placeholder="Message" id="message" name="message" required
                          onChange = {(e)=>{setMessage(e.target.value); console.log('Message:', e.target.value);}}/>
												</div>
												<div class="g-recaptcha" data-sitekey="6LfqAGMUAAAAAM3mg4JNjoSTKO4L9wMZo8Lln4Zp"></div>
												<div className="send-button">
                         

                            <Button block size="lg" className="btn-round" color="info" name="Send"
                              onClick={handleContactFormSubmit}>
                              Send
                            </Button>
                        </div>
                        <div class="form-group">{resultMessage}</div>
								</form>	
              </Col>
              
            </Row>
          </Container>
        </div>
        <FooterBlackEM />
      </div>
    </>
  );
}

export default LandingPageEM;
